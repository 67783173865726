import getTestimonialIntro from './getTestimonialIntro'

/**
 * Reformat the Testimonial object for use on the ArticleGrid component
 * @param  {Array} testimonialsNodes
 * @return {Array}
 */
const testimonialDecorator = (testimonialsNodes) => {
  return testimonialsNodes.map((testimonial) => {
    return {
      url: testimonial.url,
      image: testimonial.data.featured_image.url ? testimonial.data.featured_image : null,
      category: 'Graduate Stories',
      title: testimonial.data.title.text,
      body: getTestimonialIntro(testimonial.data),
      avatar: testimonial.data.avatar.url ? testimonial.data.avatar : null,
      name: testimonial.data.name.text,
      role: 'Graduate'
    }
  })
}

export default testimonialDecorator
