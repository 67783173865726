import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

// Generic
import Pill from '../Pill/Pill'
import Image from '../Image/Image'
import Button from '../Button/Button'

// Local
import ArticleGridAuthor from './ArticleGridAuthor'

const ArticleGrid = (props) => {
  const { articles, singleRow } = props

  return (
    <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-sm sm:max-w-none mx-auto'>
      {articles.map((article, articleIndex) => {
        return (
          <div
            key={articleIndex}
            className={classNames('shadow-lg bg-white', { 'sm:hidden lg:block': (singleRow && articleIndex >= 2) })}
          >
            <Link
              className='flex flex-col h-full'
              to={article.url}
            >
              {article.image ? (
                <div className='mb-4 flex-shrink-0'>
                  <Image
                    fallbackAlt={article.title}
                    {...article.image}
                  />
                </div>
              ) : (
                <div className='bg-gray-lighter h-32 mb-4 flex-shrink-0' />
              )}
              <div className='flex flex-col h-full px-8 pb-8'>
                <div className='space-y-4 mb-10'>
                  <div className='flex justify-center'>
                    <Pill>
                      {article.category}
                    </Pill>
                  </div>
                  <p className='h6 xl:h5'>{article.title}</p>
                  <div className='text-sm xl:text-base'>
                    <p className='line-clamp-4'>{article.body}</p>
                  </div>
                </div>
                {article.inlineCta ? (
                  <div className='mt-auto text-center'>
                    <Button
                      color='inline'
                      element='span'
                      icon
                    >
                      {article.inlineCta}
                    </Button>
                  </div>
                ) : (
                  <ArticleGridAuthor
                    avatar={article.avatar}
                    name={article.name}
                    role={article.role}
                    fallbackAlt={article.fallbackAlt}
                    isCompanyLogo={article.isCompanyLogo}
                  />
                )}
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

ArticleGrid.propTypes = {
  articles: PropTypes.array.isRequired,
  singleRow: PropTypes.bool
}

export default ArticleGrid
