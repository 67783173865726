import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import Pill from '../../shared/Pill/Pill'
import Image from '../../shared/Image/Image'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import ArticleGridWithTitle from '../../shared/ArticleGrid/ArticleGridWithTitle'
import Embed from '../../shared/Embed/Embed'
import ShareLinks from '../../shared/ShareLinks/ShareLinks'

const CaseStudyTestimonialView = (props) => {
  const { data, type, relatedItems, url } = props

  return (
    <article>
      <header>
        <div className='pt-24 lg:pt-40 bg-gray-lighter mb-4'>
          <div className='max-w-3xl mx-auto px-4 mb-10'>
            <div className='text-center'>
              <Pill className='mb-4'>
                {type === 'testimonial' ? 'Graduate Stories' : 'Case Study'}
              </Pill>
              <h2>{data.title.text}</h2>
            </div>
          </div>
          <div className='relative'>
            {data.embed.html ? (
              <div className='max-w-4xl mx-auto px-4 sm:px-5 relative z-10'>
                <Embed
                  embed={data.embed}
                  title={type === 'testimonial' ? (data.name.text ? `${data.name.text}'s story` : '') : data.title.text}
                />
              </div>
            ) : (
              <div className='max-w-md mx-auto px-4 sm:px-5 relative z-10'>
                <Image
                  fallbackAlt={data.name.text}
                  {...data.avatar}
                />
              </div>
            )}
            <div className='absolute h-1/2 bottom-0 left-0 w-full bg-white' />
          </div>
        </div>

        <Container className='text-center'>
          {type === 'testimonial' && (
            <>
              {data.name.text && <h3 className='text-primary mb-1'>{data.name.text}</h3>}
              {data.role.text && <h6 className='font-normal mb-2'>{data.role.text}</h6>}
              {data.category === 'Graduate' && <p className='text-sm mb-6'>Course: {data.course.text || 'In-house'}</p>}
            </>
          )}

          {type === 'case_study' && (
            <div className='flex justify-center mb-8'>
              <Image
                className='h-12 w-auto'
                lazyImageClassName='max-w-none w-auto h-full'
                fallbackAlt={data.title.text}
                {...data.company_logo}
              />
            </div>
          )}

          <ShareLinks
            className='mb-20'
            title={data.title.text}
            url={url}
          />
        </Container>
      </header>
      <div className='max-w-2xl mx-auto px-4 space-y-10 mb-24'>
        {data.body.map((slice) => {
          switch (slice.slice_type) {
            case 'body':
              return (
                <HtmlContent
                  key={slice.id}
                  html={slice.primary.testimonial_body.html}
                  className='text-midnight c-prose'
                />
              )

            case 'blockquote':
              return (
                <blockquote
                  key={slice.id}
                  className='border-l-2 border-primary pl-6'
                >
                  <HtmlContent
                    html={slice.primary.testimonial_blockquote_body.html}
                    className='h3 font-normal text-midnight c-prose'
                  />
                  {slice.primary.testimonial_blockquote_caption.text && (
                    <footer className='pt-4 text-xs uppercase tracking-wider font-medium'>{slice.primary.testimonial_blockquote_caption.text}</footer>
                  )}
                </blockquote>
              )
          }
        })}
      </div>
      <div className='border-b border-gray-lighter mb-20' />
      {(relatedItems.length > 0) && (
        <ArticleGridWithTitle
          className='mb-32'
          title={{
            raw: [{
              type: 'heading3',
              text: 'More Stories'
            }]
          }}
          articles={relatedItems}
          singleRow
        />
      )}
    </article>
  )
}

CaseStudyTestimonialView.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  relatedItems: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired
}

export default CaseStudyTestimonialView
