import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ShareLink = (props) => {
  const { name, url, icon, type } = props

  const [feedbackIsVisible, setFeedbackIsVisible] = useState(false)

  /**
   * Handle the click event and share to the relevant platform
   * @param  {Object} event
   * @return {Void}
   */
  const handleClick = (event) => {
    // If it's the `copy` type, we'll want to show some feedback to say it's
    // been copied
    if (type === 'copy') {
      // Show the feedback when clicked
      setFeedbackIsVisible(true)

      // Hide 2 seconds later
      setTimeout(() => {
        setFeedbackIsVisible(false)
      }, 2000)
    }

    // Default browser behaviour. Don't hijack the event.
    if (type === 'default' || type === 'new_tab') {
      return true
    }

    if (window) {
      event.preventDefault()

      // Popup the share URL in a new window
      if (type === 'popup') {
        // On desktop viewports, we use these to centre the popup in the middle
        // of the viewport
        const popupDimensions = 570
        const left = (window.innerWidth - popupDimensions) / 2
        const top = (window.innerHeight - popupDimensions) / 2

        window.open(event.currentTarget.href, 'NewWindow', `menubar=no,toolbar=no,status=no,width=${popupDimensions},height=${popupDimensions},top=${top},left=${left}`)
      }

      if (type === 'copy') {
        navigator.clipboard.writeText(event.currentTarget.href)
      }
    }
  }

  return (
    <a
      href={url}
      onClick={handleClick}
      target={type === 'new_tab' ? '_blank' : '_self'}
    >
      <span className='sr-only'>Share via {name}</span>
      <span className='flex justify-center items-center w-12 h-12 bg-primary hover:bg-primary-hover rounded-sm not-sr-only relative'>
        {icon}
        {type === 'copy' && (
          <span
            className={classNames('absolute top-1/1 left-1/2 transform -translate-x-1/2 text-xs mt-1 pointer-events-none', {
              'opacity-0': !feedbackIsVisible,
              'opacity-100': feedbackIsVisible
            })}
          >
            Copied!
          </span>
        )}
      </span>
    </a>
  )
}

ShareLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired
}

export default ShareLink
