import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import TestimonialView from '../components/views/CaseStudyTestimonialView/TestimonialView'

// Utils
import getTestimonialIntro from '../utils/getTestimonialIntro'

const TestimonialViewTemplate = ({ data, location }) => {
  return (
    <>
      <Seo
        title={data.prismicTestimonial.data.title.text}
        customTitle={data.prismicTestimonial.data.meta_title}
        description={data.prismicTestimonial.data.meta_description.text || getTestimonialIntro(data.prismicTestimonial.data)}
        image={data.prismicTestimonial.data.social_image.url || data.prismicTestimonial.data.featured_image.url}
        slug={location.pathname}
      />
      <TestimonialView
        data={data}
      />
    </>
  )
}

export default withPreview(TestimonialViewTemplate)

export const PageQuery = graphql`
  query TestimonialViewTemplateQuery (
    $uid: String!,
    $published: Date!,
  ) {
    prismicTestimonial (
      uid: {
        eq: $uid
      }
    ) {
      ...Testimonial
      data {
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
      }
    }
    moreTestimonials: allPrismicTestimonial (
      filter: {
        uid: {
          ne: $uid
        },
        data: {
          published: {
            lt: $published
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___published
      },
      limit: 3
    ) {
      nodes {
        ...Testimonial
      }
    }
  }
`
