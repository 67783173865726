/**
 * Gets the intro for the testimonial based on the available data
 * @param  {Object} data
 * @return {String}
 */
const getTestimonialIntro = (data) => {
  // If there is an intro in the CMS, use that
  if (data.intro.text) {
    return data.intro.text
  }

  // If not, get the content from the first blockquote slice...
  const blockquoteSlice = data.body.find(slice => slice.slice_type === 'blockquote')

  if (blockquoteSlice) {
    // ...and get the first paragraph from there
    const firstParagraph = blockquoteSlice.primary.testimonial_blockquote_body.raw.find(markup => markup.type === 'paragraph')

    if (firstParagraph) {
      return firstParagraph.text
    }
  }

  // If not, get the content from the first body slice...
  const bodySlice = data.body.find(slice => slice.slice_type === 'body')

  if (bodySlice) {
    // ...and get the first paragraph from there. We don't want to use any
    // headings for the intro
    const firstParagraph = bodySlice.primary.testimonial_body.raw.find(markup => markup.type === 'paragraph')

    if (firstParagraph) {
      return firstParagraph.text
    }
  }

  // If there is no matching criteria from above, return empty
  return ''
}

export default getTestimonialIntro
