import React from 'react'
import PropTypes from 'prop-types'

// Utils
import testimonialDecorator from '../../../utils/testimonialDecorator'

// Local
import CaseStudyTestimonialView from './CaseStudyTestimonialView'

const TestimonialView = (props) => {
  const { data } = props

  return (
    <CaseStudyTestimonialView
      data={data.prismicTestimonial.data}
      relatedItems={testimonialDecorator(data.moreTestimonials.nodes)}
      type='testimonial'
      url={data.prismicTestimonial.url}
    />
  )
}

TestimonialView.propTypes = {
  data: PropTypes.object.isRequired
}

export default TestimonialView
