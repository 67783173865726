import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Image from '../Image/Image'

const ArticleGridAuthor = (props) => {
  const { avatar, className, name, role, fallbackAlt, isCompanyLogo } = props

  return (
    <div className={classNames('flex items-center space-x-3 mt-auto', className)}>
      {(avatar && avatar.url) && (
        <div
          className={classNames('h-12 flex-grow-0 flex-shrink-0', {
            'max-w-xxs w-full': isCompanyLogo
          })}
        >
          <Image
            className={classNames('h-full', {
              'w-12 ': !isCompanyLogo
            })}
            lazyImageClassName={classNames('h-full', {
              'object-contain object-left': isCompanyLogo
            })}
            fallbackAlt={name || fallbackAlt}
            {...avatar}
          />
        </div>
      )}
      {(name || role) && (
        <div className='text-sm xl:text-base'>
          {name && <p className='text-primary font-medium'>{name}</p>}
          {role && <p>{role}</p>}
        </div>
      )}
    </div>
  )
}

ArticleGridAuthor.propTypes = {
  avatar: PropTypes.object,
  className: PropTypes.string,
  fallbackAlt: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  isCompanyLogo: PropTypes.bool
}

export default ArticleGridAuthor
